import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor() {}

  public url = "https://haccpflow.com/api/"; //Digital Ocean Deployment
  // public url = "http://localhost:8080/"; //Local Deployment
}
