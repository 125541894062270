import { Capacitor } from "@capacitor/core";
import { StoreProvider } from "./store/store";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "./config/config.service";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export const USER_NAME: string = "currentUser";
export const CHAT_ID: string = "chatid";
export const PROGRAM_ID: string = "programid";
export const BLUETOOTH_ID: string = "bluetoothid";
export const BLUETOOTH_NAME: string = "bluetoothname";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  // Variable for determining if a user is logged in or not
  private authStatus$ = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<string>("");
  private notifications = new BehaviorSubject<string>("");

  userID: string;
  tokenID: string;

  constructor(
    private http: HttpClient,
    private storage: StoreProvider,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService
  ) {
    this.authUserChange.subscribe((value) => {
      let user: any[] = value ? JSON.parse(value) : {};
      this.userID = user["_id"];
      this.tokenID = user["token"];
    });
  }

  async checkAuthorization() {
    const tokenVm = {
      id: this.userID,
      token: this.tokenID,
    };

    this.setIsAuthorized(false);
    await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .subscribe(
        async (user) => {
          if (user && user.token) {
            // this.setToken(user.token);
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;

            this.setIsAuthorized(true);
          }
          //return user;

          // if (user && user.token) {
          //   // if (await this.getIsItMasterLogin()) {
          //   //   // user.user.role = 'User';
          //   //   // user.user.privilege.companyAdministrator = false;
          //   // }
          //   this.setUserLogin(JSON.stringify(user.user));
          //   this.setIsAuthorized(true);
          // }
        },
        async (err) => {
          console.log("err", err);
          // await this.logout();
          // this.router.navigate(['/login']);
        }
      );
  }

  // An observable that will change when our user has logged in
  get authStatusChange() {
    return this.authStatus$.asObservable();
  }

  get authUserChange() {
    return this.user.asObservable();
  }

  get authUserNotifications() {
    return this.notifications.asObservable();
  }

  // Set the is authorised
  setIsAuthorized(value: boolean) {
    this.authStatus$.next(value);
  }

  // Get the value of the user authorisation
  isAuthorized() {
    return this.authStatus$.value;
  }

  // Set the user
  async setItem(key: string, item: string): Promise<void> {
    await this.storage.setItem(key, item);
  }

  // Get the user
  async getItem(key: string): Promise<any> {
    return await this.storage.getItem(key);
  }

  // Clear the user
  async clearItem(key: string) {
    return await this.storage.remove(key);
  }

  async setCurrentChat(chatId: string): Promise<void> {
    await this.storage.setItem(CHAT_ID, chatId);
  }

  async getCurrentChat(): Promise<string> {
    return await this.storage.getItem(CHAT_ID);
  }

  async getProgram(): Promise<string> {
    return await this.storage.getItem(PROGRAM_ID);
  }

  async setProgram(programId: string): Promise<void> {
    await this.storage.setItem(PROGRAM_ID, programId);
  }

  async getBluetooth(): Promise<string> {
    return await this.storage.getItem(BLUETOOTH_ID);
  }

  async setBluetooth(bluetoothId: string): Promise<void> {
    await this.storage.setItem(BLUETOOTH_ID, bluetoothId);
  }

  async getBluetoothName(): Promise<string> {
    return await this.storage.getItem(BLUETOOTH_NAME);
  }

  async setBluetoothName(bluetoothName: string): Promise<void> {
    await this.storage.setItem(BLUETOOTH_NAME, bluetoothName);
  }

  setUserLogin(user: any): void {
    this.user.next(user);
  }

  setNotification(notifications: any): void {
    this.notifications.next(notifications);
  }

  // Get the user
  getUser(): any {
    return this.user.value;
  }

  // Clear the user
  async clearUser() {
    return await this.storage.remove(USER_NAME);
  }

  getAPI() {
    return this.http.get<any>(this._configService.url);
  }

  async login(loginVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/login", loginVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            user.user.isAuthorized = true;
            this.router.navigate(["/dashboard"]);
            this.setIsAuthorized(true);
          }
          return user;
        })
      );
  }

  async token(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            user.user.isAuthorized = true;
            this.setUserLogin(JSON.stringify(user.user));
            this.router.navigate(["/dashboard"]);
            this.setIsAuthorized(true);
          }
          return user;
        })
      );
  }

  async tokenChangeUser(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async EditUser(userVm: any) {
    return await this.http
      .post<any>(this._configService.url + "user/editUser/", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async EditUserBio(userVm: any) {
    return await this.http
      .post<any>(this._configService.url + "user/editUserBio/", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async tokenDashboard(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
            this.setIsAuthorized(true);
          }
          return user;
        })
      );
  }

  async register(register: any) {
    return await this.http
      .post<any>(this._configService.url + "user/register", register)
      .pipe(map((data) => {}));
  }

  async getCompanyName(companyVm: any) {
    return await this.http.post<any>(
      this._configService.url + "company/getCompanyName",
      companyVm
    );
  }

  async createCalendarEvent(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "calendar/createEvent", calendarVm)
      .pipe(map((data) => {}));
  }

  async createPost(postVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "post/create", postVm)
      .pipe(map((data) => {}));
  }

  async createDish(dishVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "dish/createDish", dishVm)
      .pipe(map((data) => {}));
  }

  async createFaq(faq: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "faq/create", faq)
      .pipe(map((data) => {}));
  }

  async createProgram(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/createProgram",
      programVm
    );
  }

  async saveProgram(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/saveProgram",
      programVm
    );
  }

  async updateProgramPublication(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/updateProgramPublication",
      programVm
    );
  }

  async sendEmail(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "newsletter/sendEmail", sendEmailVm)
      .pipe(map((data) => {}));
  }

  async deleteEmail(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/deleteContactUs",
      sendEmailVm
    );
  }

  async uploadMultimedia(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/uploadMultimedia",
      formData
    );
  }

  // async getAllYoutubeVideos(multimediaVm: any) {
  //   await this.checkAuthorization();
  //   return await this.http.post<any>(
  //     this._configService.url + "multimedia/getAllYoutubeVideos",
  //     multimediaVm
  //   );
  // }

  async getAllLibrarysVideos(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/getAllLibrarysVideos",
      multimediaVm
    );
  }

  async getAllNutritionInformation(nutritionVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "nutrition/getAllNutritionInformation",
      nutritionVm
    );
  }

  async uploadProfilePicture(formData: FormData) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "multimedia/uploadProfilePicture",
        formData
      )
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async uploadPhotos(formData: FormData) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "multimedia/uploadPhotos", formData)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("system", JSON.stringify(user.system));
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async videoUpload(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/videoUpload",
      formData
    );
  }

  async certificationUpload(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "certification/uploadCertificate",
      formData
    );
  }

  async nutritionFileUpload(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "nutrition/uploadNutritionFile",
      formData
    );
  }

  async editUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .put<any>(this._configService.url + "user/role/", userVm)
      .pipe(map((data) => {}));
  }

  async editUserPrivilege(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .put<any>(this._configService.url + "user/privilege/", userVm)
      .pipe(map((data) => {}));
  }

  async addImage(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/addImage",
      formData
    );
  }

  async SystemProfilePicture(id: string, userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .put<any>(this._configService.url + "user/SystemProfilePicture/" + id, {
        userVm,
      })
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            await this.storage.setItem("system", JSON.stringify(user.system));
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async EditPassword(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "user/password", userVm)
      .pipe(map((data) => {}));
  }

  async EditProfilePicture(id: string, formData: FormData) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "multimedia/profilepicture/" + id,
        formData
      )
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            await this.storage.setItem("system", JSON.stringify(user.system));
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async getAllContactUsMessage(contactUsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/contactUs",
      contactUsVm
    );
  }

  async getAllNewsletter(newsletterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/newsletter",
      newsletterVm
    );
  }

  async getAllFaq() {
    await this.checkAuthorization();
    return await this.http.get(this._configService.url + "faq");
  }

  async getAdminAllUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAdminAllUsers",
      userVm
    );
  }

  async getCompanyAdminAllUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getCompanyAdminAllUsers",
      userVm
    );
  }

  async getCompanyAllUsersChangeUser(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getCompanyAllUsersChangeUser",
      userVm
    );
  }

  async getAdminAllCompanies(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/getAdminAllCompanies",
      userVm
    );
  }

  async getAdminAllGroups(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/getAdminAllGroups",
      userVm
    );
  }

  async getCompanyAdminAllSuppliers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/getCompanyAdminAllSuppliers",
      userVm
    );
  }

  async getSupplierInformation(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/getSupplierInformation",
      userVm
    );
  }

  async getCompanyAllSuppliers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/getCompanyAllSuppliers",
      userVm
    );
  }

  async getCompanyAllDishes(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/getCompanyAllDishes",
      userVm
    );
  }

  async getCompanyAllProductDishes(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/getCompanyAllProductDishes",
      userVm
    );
  }

  async getCompanyAdminAllDishes(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/getCompanyAdminAllDishes",
      userVm
    );
  }

  async getCompanyUsedLocations(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "location/getCompanyUsedLocations",
      userVm
    );
  }

  async getCompanyKitchens(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "kitchen/getCompanyKitchens",
      userVm
    );
  }

  async getCompanyAdminAllLocations(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "location/getCompanyAdminAllLocations",
      userVm
    );
  }

  async getCompanyAdminAllProducts(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/getCompanyAdminAllProducts",
      userVm
    );
  }

  async getCompanyAdminAllUnits(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "unit/getCompanyAdminAllUnits",
      userVm
    );
  }

  async getCompanyAllSuppliersProducts(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/getCompanyAllSuppliersProducts",
      userVm
    );
  }

  async getAllCompanyProducts(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/getAllCompanyProducts",
      userVm
    );
  }

  async getSensorReadings(readingVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "reading/getSensorReadings",
      readingVm
    );
  }

  async getCompanySensorReadings(readingVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "reading/getCompanySensorReadings",
      readingVm
    );
  }

  async getFitnessToWorkAssessmentRecords(recordVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getFitnessToWorkAssessmentRecords",
      recordVm
    );
  }

  async getFormDetails(formVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getFormDetails",
      formVm
    );
  }

  async getFitnessToWorkAssessmentRecord(formVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getFitnessToWorkAssessmentRecord",
      formVm
    );
  }

  async getAllLocationProducts(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/getAllLocationProducts",
      userVm
    );
  }

  async getAllProductInAStatus(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/getAllProductInAStatus",
      userVm
    );
  }

  async removeAllProductsFromTheArea(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/removeAllProductsFromTheArea",
      userVm
    );
  }

  async getCompanyProductDetail(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/getCompanyProductDetail",
      userVm
    );
  }

  async getCompanysDishDetails(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/getCompanysDishDetails",
      userVm
    );
  }

  async getLabelDetails(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "label/getLabel",
      userVm
    );
  }

  async getGoodsInwardsDetail(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/getGoodsInwardsDetail",
      userVm
    );
  }

  async markProductAsSold(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/markProductAsSold",
      userVm
    );
  }

  async createClientStripeAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/createClientStripeAccount",
      userVm
    );
  }

  async getClientStripeAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/getClientStripeAccount",
      userVm
    );
  }

  async getClientStripePaymentLink(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/getClientStripePaymentLink",
      userVm
    );
  }

  async getStripeAccountLinkAndMembershipLink(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/getStripeAccountLinkAndMembershipLink",
      userVm
    );
  }

  async stripeCancelSubscription(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/stripeCancelSubscription",
      userVm
    );
  }

  async stripeUpdateUserSubscriptionInformation(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "payment/stripeUpdateUserSubscriptionInformation",
      userVm
    );
  }

  async stripeReativateSubscription(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/stripeReativateSubscription",
      userVm
    );
  }
  async getAllUserFriends(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllUserFriends",
      userVm
    );
  }

  async getAllUserCoaches(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllUserCoaches",
      userVm
    );
  }

  async getAllCountries(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/getAllCountries",
      userVm
    );
  }

  async getAllCountryCities(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/getAllCitiesFromACountry",
      userVm
    );
  }

  async searchRequestAll(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/searchRequestAll",
      userVm
    );
  }

  async searchRequestCity(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/searchRequestCity",
      userVm
    );
  }

  async searchNutrition(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "nutrition/searchNutrition",
      userVm
    );
  }

  async getKitchenCleaningRecords(locationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "cleaning/getKitchenCleaningRecords",
      locationVm
    );
  }

  async getKitchenTemperatureRecords(kitchenVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "temperature/getKitchenTemperatureRecords",
      kitchenVm
    );
  }

  async readings(kitchenVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "temperature/readings",
      kitchenVm
    );
  }

  async getCleaningRecordDetails(cleaningVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "cleaning/getCleaningRecordDetails",
      cleaningVm
    );
  }

  async getTemperatureRecordDetails(temperatureVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "temperature/getTemperatureRecordDetails",
      temperatureVm
    );
  }

  async getAllUserClients(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllUserClients",
      userVm
    );
  }

  async getProgramInformation(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/getProgram",
      userVm
    );
  }

  async generateTraceabilityReport(downloadVm: any) {
    await this.checkAuthorization();
    return await this.http.post(
      this._configService.url + "reports/generateTraceabilityReport",
      downloadVm,
      { observe: "response", responseType: "blob" }
    );
  }

  async getAllMyFriends(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllMyFriends",
      userVm
    );
  }

  async getAllAdminCoachUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllAdminCoachUsers",
      userVm
    );
  }

  async getEvent(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "calendar/getEvent",
      challengeVm
    );
  }

  async getAllEvents(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "calendar/getAllEvents",
      challengeVm
    );
  }

  async getAllMessages(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/getAllMessages",
      userVm
    );
  }

  async getAllFeedActivities(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "activity-feed/getAllActivityFeeds",
      userVm
    );
  }

  async getAllConversationMessages(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/conversation",
      userVm
    );
  }

  async sendMessageResponse(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/createMessageResponse",
      userVm
    );
  }

  async searchUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/searchUsers",
      userVm
    );
  }

  async createCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/createCompany",
      companyVm
    );
  }

  async createGroup(groupVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/createGroup",
      groupVm
    );
  }

  async editCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/editCompany",
      companyVm
    );
  }

  async editGroup(groupVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/editGroup",
      groupVm
    );
  }

  // async createSupplier(supplierVm: any) {
  //   await this.checkAuthorization();
  //   return await this.http.post<any>(
  //     this._configService.url + "supplier/createSupplier",
  //     supplierVm
  //   );
  // }

  async createSupplier(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/createSupplier",
      formData
    );
  }

  async editSupplierDetails(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/editSupplierDetails",
      formData
    );
  }

  async uploadNewHACCP(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/uploadNewHACCP",
      formData
    );
  }

  async probeCalibration(probeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/probe-calibration",
      probeVm
    );
  }

  async createFitnessToWorkAssessmentForm(fitnessToWorkVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/fitness-to-work",
      fitnessToWorkVm
    );
  }

  async associateSensorToCompany(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/associateSensorToCompany",
      sensorVm
    );
  }

  async newGoodsInwards(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/newGoodsInwards",
      formData
    );
  }

  async newPestControlRecord(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/newPestControlRecord",
      formData
    );
  }

  async updateGoodsInwards(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/updateGoodsInwards",
      formData
    );
  }

  async SearchItemsTraceabilityCode(ItemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/SearchItemsTraceabilityCode",
      ItemVm
    );
  }

  async SearchItemsRecords(ItemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/SearchItemsRecords",
      ItemVm
    );
  }

  async SearchGoodsInwardsItemsRecords(ItemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "goods-inwards/SearchGoodsInwardsItemsRecords",
      ItemVm
    );
  }

  async SearchTemperatureMonitoringRecords(ItemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "reading/SearchTemperatureMonitoringRecords",
      ItemVm
    );
  }

  async findSensor(ItemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/findSensor",
      ItemVm
    );
  }

  async getCompanysAllCleaningsRecords(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "cleaning/getCompanysAllCleaningsRecords",
      companyVm
    );
  }

  async getCompanysAllTemperaturesRecords(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "temperature/getCompanysAllTemperaturesRecords",
      companyVm
    );
  }

  async cleaningChecklist(cleaningChecklistVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "cleaning/cleaningChecklist",
      cleaningChecklistVm
    );
  }

  async kitchensTemperatureCheck(temperatureCheckVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "temperature/kitchensTemperatureCheck",
      temperatureCheckVm
    );
  }

  async editSupplier(supplierVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/editSupplier",
      supplierVm
    );
  }

  async createLocation(locationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "location/createLocation",
      locationVm
    );
  }

  async editLocation(locationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "location/editLocation",
      locationVm
    );
  }

  async editSensor(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/editSensor",
      sensorVm
    );
  }

  async updateCompanyMaxUsers(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/updateCompanyMaxUsers",
      companyVm
    );
  }

  async updateGroupMaxUsers(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/updateGroupMaxUsers",
      companyVm
    );
  }

  async getAdminCompanyInfo(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/getAdminCompanyInfo",
      companyVm
    );
  }

  async getAdminGroupInfo(groupVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/getAdminGroupInfo",
      groupVm
    );
  }

  async groupStatistics(groupVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/groupStatistics",
      groupVm
    );
  }

  async getSystemInfo(systemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "system/getAdminSystemInfo",
      systemVm
    );
  }

  async createKitchen(kitchenVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "kitchen/createKitchen",
      kitchenVm
    );
  }
  async createNewLabel(labelVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "label/createNewLabel",
      labelVm
    );
  }

  async editKitchen(kitchenVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "kitchen/editKitchen",
      kitchenVm
    );
  }

  async createProduct(productVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/createProduct",
      productVm
    );
  }

  async editProduct(productVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/editProduct",
      productVm
    );
  }

  async editDish(dishVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/editDish",
      dishVm
    );
  }

  async createUnit(unitVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "unit/createUnit",
      unitVm
    );
  }

  async editUnit(unitVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "unit/editUnit",
      unitVm
    );
  }

  async sendNotification(notification: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "notification/notify", notification)
      .pipe(map((data) => {}));
  }

  async sendAdminEmail(emailVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "notification/sendAdminEmail",
        emailVm
      )
      .pipe(map((data) => {}));
  }

  async getAllNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/getAllNotifications",
      notificationVm
    );
  }

  async getAllSystemNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/getAllSystemNotifications",
      notificationVm
    );
  }

  async getAllSystemEmails(emailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/getAllSystemEmails",
      emailVm
    );
  }

  async getNavbarNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        // this._configService.url + "notification/getNavbarNotifications",
        this._configService.url + "notification/getAllNotifications",
        notificationVm
      )
      .pipe(
        map(
          async (notifications) => {
            this.setNotification(JSON.stringify(notifications));
          },
          (err) => {
            this.setNotification("");
          }
        )
      );
  }

  async markAllNotificationsAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/markAllNotificationsAsRead",
      notificationVm
    );
  }

  async issueNewVerificationEmail(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/issueNewVerificationEmail",
      verificationEmailVm
    );
  }

  async areTemperaturesChecked(temperatureVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "temperature/areTemperaturesChecked",
      temperatureVm
    );
  }

  async checkIfSensorAreActive(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/checkIfSensorAreActive",
      sensorVm
    );
  }

  async sendUserCompanyEmailInvitation(invitationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/sendUserCompanyEmailInvitation",
      invitationVm
    );
  }

  async sendUserCompanyAdminEmailInvitation(invitationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/sendUserCompanyAdminEmailInvitation",
      invitationVm
    );
  }

  async emailVerification(verificationEmailVm: any) {
    return await this.http.post<any>(
      this._configService.url + "verificationGeneration/emailVerification",
      verificationEmailVm
    );
  }

  async markNotificationAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/markNotificationAsRead",
      notificationVm
    );
  }

  async markAllLocationNotificationsAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "notification/markAllLocationNotificationsAsRead",
      notificationVm
    );
  }

  async deleteAllLocationNotificationsAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "notification/deleteAllLocationNotificationsAsRead",
      notificationVm
    );
  }

  async forgotPassword(forgotPasswordVm: any) {
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/newPasswordRequestCreation",
      forgotPasswordVm
    );
  }

  async resetPassword(forgotPasswordVm: any) {
    return await this.http.post<any>(
      this._configService.url + "verificationGeneration/resetPassword",
      forgotPasswordVm
    );
  }

  async checkPasswordExpiration(forgotPasswordVm: any) {
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/checkPasswordExpiration",
      forgotPasswordVm
    );
  }

  async logoutNotification(user: string) {
    return await this.http
      .put<any>(this._configService.url + "user/logout/" + user, {})
      .pipe(map((data) => {}));
  }

  async download(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post(
      this._configService.url + "multimedia/download/",
      multimediaVm,
      { responseType: "blob" }
    );
  }

  async deleteFaq(faqVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "faq/deleteFaq",
      faqVm
    );
  }

  async deleteContactUsMessage(contactUsMessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/deleteContactUs",
      contactUsMessageVm
    );
  }

  async deleteUser(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/deleteUser",
      userVm
    );
  }

  async deleteUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/deleteUserAccount",
      userVm
    );
  }

  async resetProfilePicture(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "user/resetProfilePicture", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            await this.storage.setItem("system", JSON.stringify(user.system));
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async deleteAdminUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/deleteAdminUserAccount",
      userVm
    );
  }

  async removeUserFromCompany(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/removeUserFromCompany",
      userVm
    );
  }

  async deleteAdminCompanyAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/deleteAdminCompanyAccount",
      userVm
    );
  }

  async deleteAdminGroupAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/deleteAdminGroupAccount",
      userVm
    );
  }

  async deleteCompanyAdminSupplier(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/deleteCompanyAdminSupplier",
      userVm
    );
  }

  async deleteCompanyAdminLocation(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "location/deleteCompanyAdminLocation",
      userVm
    );
  }

  async deleteCompanyAdminKitchen(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "kitchen/deleteCompanyAdminKitchen",
      userVm
    );
  }

  async deleteCompanyAdminProduct(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/deleteCompanyAdminProduct",
      userVm
    );
  }

  async deleteCompanyAdminDish(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/deleteCompanyAdminDish",
      userVm
    );
  }

  async deleteCompanyAdminUnit(unitVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "unit/deleteCompanyAdminUnit",
      unitVm
    );
  }

  async deleteMultimedia(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/deleteMultimedia",
      multimediaVm
    );
  }

  async deleteAdminEvent(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "calendar/deleteAdminEvent",
      companyVm
    );
  }

  async enableAdminUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/enableAdminUserAccount",
      userVm
    );
  }

  async activateAdminCompanyAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/activateAdminCompanyAccount",
      userVm
    );
  }

  async activateAdminGroupAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/activateAdminGroupAccount",
      userVm
    );
  }

  async updateCompanySubscription(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/updateCompanySubscription",
      userVm
    );
  }

  async updateGroupSubscription(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "group/updateGroupSubscription",
      userVm
    );
  }

  async updateSystemInformation(systemVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "system/editSystem",
      systemVm
    );
  }

  async getUserProfile(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getUserProfile",
      userVm
    );
  }

  async activateProductAdminCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "product/activateProductAdminCompany",
      companyVm
    );
  }

  async activateDishAdminCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "dish/activateDishAdminCompany",
      companyVm
    );
  }

  async activateAdminCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/activateAdminCompany",
      companyVm
    );
  }

  async activateAdminSensor(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/activateAdminSensor",
      sensorVm
    );
  }

  async deleteAdminSensor(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/deleteAdminSensor",
      sensorVm
    );
  }

  async deleteCompanyAdminSensor(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/deleteCompanyAdminSensor",
      sensorVm
    );
  }

  async getAllAdminSensors(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/getAllSensors",
      sensorVm
    );
  }

  async getAllCompanyLabels(labelVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "label/getAllLabels",
      labelVm
    );
  }

  async getAllCompanySensors(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/getAllCompanySensors",
      sensorVm
    );
  }

  async getAllProbeCalibrationsRecords(calibrationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getAllCompanysProbeCalibrationRecords",
      calibrationVm
    );
  }

  async getAllFitnessToWorkAssessmentRecords(fitnessToWorkVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getAllFitnessToWorkAssessmentRecords",
      fitnessToWorkVm
    );
  }

  async getAllPestControlRecords(pestControlVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getAllPestControlRecords",
      pestControlVm
    );
  }

  async getAllHACCPApprovedSuppliers(supplierVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "supplier/getAllHACCPApprovedSuppliers",
      supplierVm
    );
  }

  async getAllHygieneInspectionsRecords(hygieneInspectionVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getAllHygieneInspectionRecords",
      hygieneInspectionVm
    );
  }

  async getHygieneInspectionRecord(hygieneInspectionVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/getHygieneInspectionRecord",
      hygieneInspectionVm
    );
  }

  async createHygieneInspection(hygieneVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "forms/hygiene-inspection",
      hygieneVm
    );
  }

  async changeAdminUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/changeAdminUserRole",
      userVm
    );
  }

  async changeAdminUserCompany(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/changeAdminUserCompany",
      userVm
    );
  }

  async changeAdminUserGroup(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/changeAdminUserGroup",
      userVm
    );
  }

  async changeAdminCompanyGroup(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/changeAdminCompanyGroup",
      userVm
    );
  }

  async changeAdminSensorsCompany(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/changeAdminSensorsCompany",
      sensorVm
    );
  }

  async changeAdminSensorsKitchen(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/changeAdminSensorsKitchen",
      sensorVm
    );
  }

  async updateSensorsTemperatureAlertRange(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/updateSensorsTemperatureAlertRange",
      sensorVm
    );
  }

  async updateSensorsHumidityAlertRange(sensorVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "sensor/updateSensorsHumidityAlertRange",
      sensorVm
    );
  }

  async changeUserCompanyRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/changeUserCompanyRole",
      userVm
    );
  }

  async logout() {
    const firebaseToken = await this.storage.getItem("firebaseToken");

    if (
      Capacitor.getPlatform() === "android" ||
      Capacitor.getPlatform() === "ios"
    ) {
      const user = JSON.parse(await this.storage.getItem("currentUser"));
      const userVm = {
        id: user._id,
        token: user.token,
        firebaseToken: firebaseToken,
      };
      await this.http.post<any>(
        this._configService.url + "user/unregisterForPushNotifications",
        userVm
      );
    }
    this.modalService.dismissAll();
    await this.setIsAuthorized(false);
    await this.clearUser();
    await this.storage.clear();
    await this.storage.setItem("firebaseToken", firebaseToken);
    // await this.setUserLogin(JSON.stringify(""));
  }
}
