import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config/config.service';

interface SubcriptionResponse {
  success: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
    private readonly _configService: ConfigService) { }

  messageContactUs(message: any) {
    return this.http.post<any>(this._configService.url + 'newsletter/messageContactUs', message);
  }

  updateNewsLetter(name, email) {
    return this.http.post<any>(this._configService.url + 'newsletter', { name, email });
  }

  removeNewsLetter(email) {
    return this.http.delete<any>(this._configService.url + 'newsletter/' + email);
  }
}